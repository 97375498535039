export class Customer {
  id: number;
  economicGroupId: number;
  name: string;
  cpfCnpj: string;
  email: string;
  zipCode: string;
  classification: string;
  firstOrderDate: Date;
  cityId: number;
  sellerId: number;
  stateId: number;
  active: boolean;
  origin: string;
  cnae: string;
  createdAt: Date;
  updatedAt: Date;
}
