<app-header></app-header>
<div class="spaces-content">
  <mat-grid-list [cols]="4" rowHeight="240px">
    <mat-grid-tile *ngFor="let item of dealerships" (click)="selectDealership(item)">
      <a class="dealership-box">
        <h3>{{item.name}}</h3>
      </a>
    </mat-grid-tile>
  </mat-grid-list>
</div>
