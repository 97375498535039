import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-support',
  templateUrl: './admin-support.component.html',
  styleUrls: ['./admin-support.component.scss']
})
export class AdminSupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
