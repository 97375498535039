import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { HeaderModule } from '../shared/header/header.module';
import { MaterialModule } from '../material/material.module';
import { AdminDashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from '../app-routing.module';
import { AdminInvoicesComponent } from './admin-invoices/admin-invoices.component';
import { AdminCustomersComponent } from './admin-customers/admin-customers.component';
import { AdminSellersComponent } from './admin-sellers/admin-sellers.component';
import { AdminProductsComponent } from './admin-products/admin-products.component';
import { AdminGroupsComponent } from './admin-groups/admin-groups.component';
import { AdminDealershipsComponent } from './admin-dealerships/admin-dealerships.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AdminSupportComponent } from './admin-support/admin-support.component';
import { DxDataGridModule } from 'devextreme-angular';
import { CustomerFormComponent } from './admin-customers/customer-form/customer-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SellerFormComponent } from './admin-sellers/seller-form/seller-form.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminDashboardComponent,
    AdminInvoicesComponent,
    AdminCustomersComponent,
    AdminSellersComponent,
    AdminProductsComponent,
    AdminGroupsComponent,
    AdminDealershipsComponent,
    AdminReportsComponent,
    AdminSettingsComponent,
    AdminSupportComponent,
    CustomerFormComponent,
    SellerFormComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    MaterialModule,
    AppRoutingModule,
    DxDataGridModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class AdminModule { }
