import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceComponent } from './space.component';
import { MaterialModule } from '../material/material.module';
import { HeaderModule } from '../shared/header/header.module';

@NgModule({
  declarations: [SpaceComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MaterialModule,
  ]
})
export class SpaceModule { }
