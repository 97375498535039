<app-empty-header></app-empty-header>
<div class="centered-content">
  <div class="content">
    <div class="login-form-content">
      <div class="login-title">
        gestão de leads
      </div>

      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="submitForm()">
        <mat-form-field class="login-full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Digite aqui o seu email" formControlName="email"/>
          <mat-error *ngIf="errorHandling('email', 'required')">
            Você precisa informar um <strong>email</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="login-full-width">
          <mat-label>Senha</mat-label>
          <input matInput placeholder="******" formControlName="password"/>
          <mat-error *ngIf="errorHandling('password', 'required')">
            Você precisa informar uma <strong>senha</strong>
          </mat-error>
        </mat-form-field>

        <div class="login-button-row">
          <button mat-flat-button color="primary" class="login-button">
            Entrar
          </button>
        </div>

        <div class="forgot-password-row">
          <a mat-button class="forgot-password-link">
            Esqueci minha senha
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
