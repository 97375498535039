<div class="customer-form-container">
  <form class="customer-form" [formGroup]="customerForm" (ngSubmit)="submitForm()">

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="" formControlName="name" [(ngModel)]="customer.name"/>
        <mat-hint>Nome fantasia ou razão social</mat-hint>
        <mat-error *ngIf="errorHandling('name', 'required')">
          Você precisa informar um <strong>nome</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Grupo Econômico</mat-label>
        <mat-select formControlName="economicGroupId" [(value)]="customer.economicGroupId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let economicGroup of economicGroups" [value]="economicGroup.id">
            {{economicGroup.name}}
          </mat-option>
        </mat-select>
        <mat-hint></mat-hint>
        <mat-error *ngIf="errorHandling('economicGroupId', 'required')">
          Você precisa informar um <strong>grupo econômico</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>CPF/CNPJ</mat-label>
        <input matInput placeholder="" formControlName="cpfCnpj" [(ngModel)]="customer.cpfCnpj"/>
        <mat-hint></mat-hint>
        <mat-error *ngIf="errorHandling('cpfCnpj', 'required')">
          Você precisa informar um <strong>CPF ou CNPJ</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="" formControlName="email" [(ngModel)]="customer.email"/>
        <mat-hint></mat-hint>
        <mat-error *ngIf="errorHandling('email', 'required')">
          Você precisa informar um <strong>email</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Classificação</mat-label>
        <input matInput placeholder="" formControlName="classification" [(ngModel)]="customer.classification"/>
        <mat-hint></mat-hint>
        <mat-error *ngIf="errorHandling('classification', 'required')">
          Você precisa informar uma <strong>classificação</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <a mat-flat-button color="primary" class="form-button" (click)="router.navigate(['/admin/customers'])">
        Voltar
      </a>
      <button mat-flat-button color="primary" class="form-button">
        Salvar
      </button>
    </div>

  </form>
</div>
