import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminSellerService } from './admin-seller.service';
import DataSource from 'devextreme/data/data_source';
import { buildDataSourceParams } from 'src/app/utils/requests';
import { PaginatedResult } from 'src/app/shared/interfaces/paginated-result';

@Component({
  selector: 'app-admin-sellers',
  templateUrl: './admin-sellers.component.html',
  styleUrls: ['./admin-sellers.component.scss']
})
export class AdminSellersComponent implements OnInit {
  gridDataSource: any = {};

  constructor(
    private adminSellerService: AdminSellerService,
    public router: Router,
  ) {
    this.gridDataSource = new DataSource({
      key: 'id',
      load: async (loadOptions) => {
        const params = buildDataSourceParams(loadOptions);

        return new Promise((resolve, reject) => {
          this.adminSellerService.getSellers(params)
            .subscribe(async (data: PaginatedResult) => {
              resolve(data);
            }, (error) => {
              reject(error);
            });
        });
      },
    });
  }

  ngOnInit(): void {
  }

  edit(event) {
    if (event && event.id) {
      this.router.navigate([`/admin/sellers/details/${event.id}`]);
    }
  }

}
