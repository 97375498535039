import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../../../app/shared/interfaces/paginated-result';
import { retry, catchError } from 'rxjs/operators';
import { handleError, getHeaders } from 'src/app/utils/requests';
import { GlobalConstants } from 'src/app/utils/global-constants';
import { Seller } from '../../../app/models/seller';

@Injectable({
  providedIn: 'root'
})
export class AdminSellerService {

  constructor(private http: HttpClient) { }

  getSellers(params: HttpParams): Observable<PaginatedResult> {
    const parameters = getHeaders();
    parameters.params = params;

    return this.http
      .get<PaginatedResult>(`${GlobalConstants.apiURL}/sellers`, parameters)
      .pipe(
        retry(2),
        catchError(handleError<PaginatedResult>('getSellers', {} as PaginatedResult))
      );
  }

  getSeller(id: number): Observable<Seller> {
    return this.http.get<Seller>(`${GlobalConstants.apiURL}/sellers/${id}`, getHeaders())
      .pipe(
        retry(2),
        catchError(handleError<Seller>('getSeller', {} as Seller))
      );
  }

  updateSeller(id: number, data: any): any {
    return this.http.put<any>(`${GlobalConstants.apiURL}/sellers/${id}`, data, getHeaders())
      .pipe(
        catchError(handleError<Seller>('updateSeller', {} as Seller))
      );
  }

  createSeller(data: any): any {
    return this.http.post<any>(`${GlobalConstants.apiURL}/sellers`, data, getHeaders())
      .pipe(
        catchError(handleError<Seller>('createSeller', {} as Seller))
      );
  }
}
