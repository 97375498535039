import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss']
})
export class AdminReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
