import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getHeaders, handleError } from 'src/app/utils/requests';
import { GlobalConstants } from 'src/app/utils/global-constants';
import { EconomicGroup } from 'src/app/models/economic-group';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminEconomicGroupService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<EconomicGroup[]> {
    return this.http
      .get<EconomicGroup[]>(`${GlobalConstants.apiURL}/economic-groups`, getHeaders())
      .pipe(
        retry(2),
        catchError(handleError<any>('getAll', {} as EconomicGroup[]))
      );
  }
}
