import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { AdminComponent } from './admin/admin.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { AdminInvoicesComponent } from './admin/admin-invoices/admin-invoices.component';
import { AdminCustomersComponent } from './admin/admin-customers/admin-customers.component';
import { AdminSellersComponent } from './admin/admin-sellers/admin-sellers.component';
import { AdminProductsComponent } from './admin/admin-products/admin-products.component';
import { AdminGroupsComponent } from './admin/admin-groups/admin-groups.component';
import { AdminDealershipsComponent } from './admin/admin-dealerships/admin-dealerships.component';
import { AdminReportsComponent } from './admin/admin-reports/admin-reports.component';
import { AdminSettingsComponent } from './admin/admin-settings/admin-settings.component';
import { AdminSupportComponent } from './admin/admin-support/admin-support.component';
import { CustomerFormComponent } from './admin/admin-customers/customer-form/customer-form.component';
import { SellerFormComponent } from './admin/admin-sellers/seller-form/seller-form.component';
import { SpaceComponent } from './space/space.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'spaces', component: SpaceComponent},
  {path: 'login', component: LoginComponent},
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {path: '', redirectTo: '/admin/dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: AdminDashboardComponent},
      {path: 'invoices', component: AdminInvoicesComponent},
      {path: 'customers', component: AdminCustomersComponent},
      {path: 'customers/details/:id', component: CustomerFormComponent},
      {path: 'customers/details', component: CustomerFormComponent},
      {path: 'sellers', component: AdminSellersComponent},
      {path: 'sellers/details/:id', component: SellerFormComponent},
      {path: 'sellers/details', component: SellerFormComponent},
      {path: 'products', component: AdminProductsComponent},
      {path: 'economic-groups', component: AdminGroupsComponent},
      {path: 'dealerships', component: AdminDealershipsComponent},
      {path: 'reports', component: AdminReportsComponent},
      {path: 'settings', component: AdminSettingsComponent},
      {path: 'support', component: AdminSupportComponent},
    ]
  },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
