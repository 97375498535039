import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../../../app/shared/interfaces/paginated-result';
import { getHeaders, handleError } from 'src/app/utils/requests';
import { GlobalConstants } from 'src/app/utils/global-constants';
import { retry, catchError } from 'rxjs/operators';
import { Dealership } from '../../../app/models/dealership';

@Injectable({
  providedIn: 'root'
})
export class AdminDealershipService {

  constructor(private http: HttpClient) { }

  getDealerships(params: HttpParams): Observable<PaginatedResult> {
    const parameters = getHeaders();
    parameters.params = params;

    return this.http
      .get<PaginatedResult>(`${GlobalConstants.apiURL}/dealerships`, parameters)
      .pipe(
        retry(2),
        catchError(handleError<PaginatedResult>('getDealerships', {} as PaginatedResult))
      );
  }

  getDealership(id: number): Observable<Dealership>  {
    return this.http.get<Dealership>(`${GlobalConstants.apiURL}/dealerships/${id}`, getHeaders())
      .pipe(
        retry(2),
        catchError(handleError<Dealership>('getDealership', {} as Dealership))
      );
  }
}
