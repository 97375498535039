import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

function isNotEmpty(value: any): boolean {
  return value !== undefined && value !== null && value !== '';
}

export function buildDataSourceParams(loadOptions: any) {
  let params: HttpParams = new HttpParams();
  [
    'skip',
    'take',
    'requireTotalCount',
    'requireGroupCount',
    'sort',
    'filter',
    'totalSummary',
    'group',
    'groupSummary'
  ].forEach((i) => {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
      params = params.set(i, JSON.stringify(loadOptions[i]));
    }
  });

  return params;
}

export function getHeaders() {
  return {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
    .append('Authorization', `Bearer ${localStorage.getItem('token')}`),
    params: {}
  };
}

export function handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
