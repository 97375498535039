<mat-toolbar color="primary" class="theme-header">
  <mat-toolbar-row>
    <div class="header-start-content">
      <div>
        <span class="month-title">MÊS ATUAL</span>
      </div>
      <div>
        <span class="month-value">{{dateReference | date:'MMMM'}} {{dateReference | date:'yyyy'}}</span>
      </div>
    </div>
    <span class="header-spacer"></span>
    <div class="header-middle-content">
      <div class="user-detail">
        <div class="greeting">
          Bem-vindo
        </div>
        <div class="name">
          {{user.name}}
        </div>
        <div class="role">
          vendedor
        </div>
      </div>
    </div>
    <div class="avatar">
      <img height="60" width="60" src="../../../assets/images/avatar-demo.png" alt="Avatar">
    </div>
    <span class="header-spacer"></span>
    <a [routerLink]="['/']">
      <img src="../../../assets/images/logo-renault.png" alt="Logo" class="header-image">
    </a>
  </mat-toolbar-row>
</mat-toolbar>
