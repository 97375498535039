import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.reactiveForm();
  }

  reactiveForm() {
    this.loginForm = this.fb.group({
      password: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.loginForm.controls[control].hasError(error);
  }

  async submitForm() {
    const formData = this.loginForm.value;

    if (!formData.email || !formData.password) {
      this.snackBar.open('Dados inválidos!!', 'Tente novamente', {
        duration: 2000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    }

    this.authService.login(formData).subscribe((loginResult) => {
      if (!loginResult.user || !loginResult.user.token) {
        return this.snackBar.open('Usuário ou senha inválidos!!', 'Tente novamente', {
          duration: 2000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
      }

      const dealerships = (loginResult.dealerships || []).map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      localStorage.setItem('token', loginResult.user.token);
      const user = {
        name: loginResult.user.name,
        email: loginResult.user.email,
      };
      localStorage.setItem('user', JSON.stringify(user));
      this.router.navigate(['spaces', {dealerships: JSON.stringify(dealerships)}]);
    });
  }
}
