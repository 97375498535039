<h3>Concessionárias</h3>

<dx-data-grid
    id="gridContainer"
    [dataSource]="gridDataSource"
    [showRowLines]="true"
    [rowAlternationEnabled]="true"
    [showColumnLines]="true"
    [allowColumnResizing]="true"
    [columnMinWidth]="60"
    [columnAutoWidth]="false"
    [hoverStateEnabled]="true"
    [remoteOperations]="true"
    [showBorders]="true"
    [showRowLines]="true"
    [allowColumnReordering]="true"
    [filterSyncEnabled]="true"
    (onCellClick)="edit($event.data)">

    <dxo-filter-row visible="true"></dxo-filter-row>
    <dxo-header-filter [visible]="false"></dxo-header-filter>

    <dxi-column
      [width]="240"
      dataField="name"
      caption="Nome"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="businessName"
      caption="Fantasia"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="alias"
      caption="Apelido"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="email"
      caption="Email"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="cnpj"
      caption="CNPJ"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>

    <dxo-paging [pageSize]="10"></dxo-paging>

    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[2, 5, 10, 20]"
      [showInfo]="true"
      infoText="Página #{0}. Total: {1} ({2} itens)"
      [showNavigationButtons]="true">
    </dxo-pager>
</dx-data-grid>
