export class Seller {
  id: number;
  name: string;
  externalId: string;
  accountId: number;
  cpfCnpj: string;
  email: string;
  skype: string;
  whatsapp: string;
  phone: string;
  economicGroupId: number;
  createdAt: Date;
  updatedAt: Date;
}
