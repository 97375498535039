<app-header></app-header>
<div class="admin-dashboard-content">
  <mat-drawer-container class="drawer-container" autosize>
    <mat-drawer #drawer class="drawer-sidenav" mode="side">
      <div class="menu-content">
        <mat-list>
          <mat-list-item role="listitem" *ngFor="let item of menuItems" (click)="goTo(item.link)">
            <mat-icon mat-list-icon>{{item.icon}}</mat-icon>
            <div mat-line>{{item.title}}</div>
            <!-- <div mat-line>0</div> -->
          </mat-list-item>
      </mat-list>
      </div>
    </mat-drawer>

    <div class="drawer-sidenav-content">
      <div class="content">
        <div>
          <button mat-icon-button color="accent" (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="subpages-area">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

  </mat-drawer-container>
</div>
