import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DashboardService } from './shared/dashboard.service';
import CustomStore from 'devextreme/data/custom_store';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  customDataSource: CustomStore;
  dataSourse: DataSource;
  gridFilter: Array<any> = null;
  tiles: Tile[] = [
    {text: 'One', cols: 3, rows: 1, color: '#007FB2'},
    {text: 'Two', cols: 1, rows: 2, color: '#49B2CD'},
    {text: 'Three', cols: 1, rows: 1, color: '#006893'},
    {text: 'Four', cols: 2, rows: 1, color: '#3F9CB4'},
  ];

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.retrieveAllData();
  }

  retrieveAllData() {
    this.customDataSource = new CustomStore({
      key: ['_id'],
      loadMode: 'raw',
      load: () => {
        return this.dashboardService.getData()
          .toPromise()
          .catch(() => {
            const error = new Error('Data loading error');
            throw error;
          });
      }
    });
  }

}
