import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/models/customer';
import { retry, catchError } from 'rxjs/operators';
import { PaginatedResult } from 'src/app/shared/interfaces/paginated-result';
import { getHeaders, handleError } from 'src/app/utils/requests';
import { GlobalConstants } from 'src/app/utils/global-constants';

@Injectable({
  providedIn: 'root'
})
export class AdminCustomerService {

  constructor(private http: HttpClient) { }

  getCustomers(params: HttpParams): Observable<PaginatedResult> {
    const parameters = getHeaders();
    parameters.params = params;

    return this.http
      .get<PaginatedResult>(`${GlobalConstants.apiURL}/customers`, parameters)
      .pipe(
        retry(2),
        catchError(handleError<PaginatedResult>('getCustomers', {} as PaginatedResult))
      );
  }

  getCustomer(id: number): Observable<Customer> {
    return this.http.get<Customer>(`${GlobalConstants.apiURL}/customers/${id}`, getHeaders())
      .pipe(
        retry(2),
        catchError(handleError<Customer>('getCustomer', {} as Customer))
      );
  }

  updateCustomer(id: number, data: any): any {
    return this.http.put<any>(`${GlobalConstants.apiURL}/customers/${id}`, data, getHeaders())
      .pipe(
        catchError(handleError<Customer>('updateCustomer', {} as Customer))
      );
  }

  createCustomer(data: any): any {
    return this.http.post<any>(`${GlobalConstants.apiURL}/customers`, data, getHeaders())
      .pipe(
        catchError(handleError<Customer>('createCustomer', {} as Customer))
      );
  }

}
