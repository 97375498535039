<div class="seller-form-container">
  <form class="seller-form" [formGroup]="sellerForm" (ngSubmit)="submitForm()">

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="" formControlName="name" [(ngModel)]="seller.name"/>
        <mat-hint>Nome completo</mat-hint>
        <mat-error *ngIf="errorHandling('name', 'required')">
          Você precisa informar um <strong>nome</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>ID externo</mat-label>
        <input matInput placeholder="" formControlName="externalId" [(ngModel)]="seller.externalId"/>
        <mat-hint>ID no sistema originador</mat-hint>
        <mat-error *ngIf="errorHandling('externalId', 'required')">
          Você precisa informar um <strong>ID externo</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>CPF/CNPJ</mat-label>
        <input matInput placeholder="" formControlName="cpfCnpj" [(ngModel)]="seller.cpfCnpj"/>
        <mat-hint></mat-hint>
        <mat-error *ngIf="errorHandling('cpfCnpj', 'required')">
          Você precisa informar um <strong>CPF ou CNPJ</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="" formControlName="email" [(ngModel)]="seller.email"/>
        <mat-hint></mat-hint>
        <mat-error *ngIf="errorHandling('email', 'required')">
          Você precisa informar um <strong>email</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Grupo Econômico</mat-label>
        <mat-select formControlName="economicGroupId" [(value)]="seller.economicGroupId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let economicGroup of economicGroups" [value]="economicGroup.id">
            {{economicGroup.name}}
          </mat-option>
        </mat-select>
        <mat-hint></mat-hint>
        <mat-error *ngIf="errorHandling('economicGroupId', 'required')">
          Você precisa informar um <strong>grupo econômico</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Skype</mat-label>
        <input matInput placeholder="" formControlName="skype" [(ngModel)]="seller.skype"/>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Whatsapp</mat-label>
        <input matInput placeholder="" formControlName="whatsapp" [(ngModel)]="seller.whatsapp"/>
      </mat-form-field>
    </div>

    <div class="form-line">
      <mat-form-field appearance="outline">
        <mat-label>Telefone</mat-label>
        <input matInput placeholder="" formControlName="phone" [(ngModel)]="seller.phone"/>
      </mat-form-field>
    </div>

    <div class="form-line">
      <a mat-flat-button color="primary" class="form-button" (click)="router.navigate(['/admin/sellers'])">
        Voltar
      </a>
      <button mat-flat-button color="primary" class="form-button">
        Salvar
      </button>
    </div>

  </form>
</div>
