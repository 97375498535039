<mat-grid-list cols="2" rowHeight="100px">
  <mat-grid-tile>
    <div class="left-align-forced">
      <h3>Clientes</h3>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="right-align-forced">
      <a mat-flat-button color="primary" class="form-button" (click)="router.navigate(['/admin/customers/details'])">
        Novo
      </a>
    </div>
  </mat-grid-tile>
</mat-grid-list>


<dx-data-grid
    id="gridContainer"
    [dataSource]="gridDataSource"
    [showRowLines]="true"
    [rowAlternationEnabled]="true"
    [showColumnLines]="true"
    [allowColumnResizing]="true"
    [columnMinWidth]="60"
    [columnAutoWidth]="false"
    [hoverStateEnabled]="true"
    [remoteOperations]="true"
    [showBorders]="true"
    [showRowLines]="true"
    [allowColumnReordering]="true"
    [filterSyncEnabled]="true"
    (onCellClick)="edit($event.data)">

    <dxo-filter-row visible="true"></dxo-filter-row>
    <dxo-header-filter [visible]="false"></dxo-header-filter>

    <dxi-column
      [width]="240"
      dataField="name"
      caption="Nome"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="cpfCnpj"
      caption="CPF-CNPJ"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="email"
      caption="Email"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="classification"
      caption="Classificação"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="cnae"
      caption="Cnae"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="firstOrderDate"
      caption="Primeira compra"
      dataType="date"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>
    <dxi-column
      dataField="origin"
      caption="Origem"
      dataType="string"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowEditing]="false">
    </dxi-column>

    <dxo-paging [pageSize]="10"></dxo-paging>

    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[2, 5, 10, 20]"
      [showInfo]="true"
      infoText="Página #{0}. Total: {1} ({2} itens)"
      [showNavigationButtons]="true">
    </dxo-pager>
</dx-data-grid>
