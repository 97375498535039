import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getHeaders, handleError } from 'src/app/utils/requests';
import { GlobalConstants } from 'src/app/utils/global-constants';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Login } from '../shared/interfaces/login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(login: Login): Observable<any> {
    return this.http.post<any>(`${GlobalConstants.apiURL}/auth/login`, login, getHeaders())
      .pipe(
        catchError(handleError<any>('login', {} as any))
      );
  }
}
