import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';

interface MenuItem {
  title: string;
  icon: string;
  link: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit {
  @ViewChild('drawer', { static: false }) public drawer: MatDrawer;

  menuItems: MenuItem[] = [
    {
      title: 'Dashboard',
      icon: 'dashboard',
      link: '/admin/dashboard',
    },
    {
      title: 'Notas Fiscais',
      icon: 'dialpad',
      link: '/admin/invoices',
    },
    {
      title: 'Clientes',
      icon: 'how_to_reg',
      link: '/admin/customers',
    },
    {
      title: 'Vendedores',
      icon: 'supervisor_account',
      link: '/admin/sellers',
    },
    {
      title: 'Produtos',
      icon: 'shopping_basket',
      link: '/admin/products',
    },
    {
      title: 'Grupos',
      icon: 'list_alt',
      link: '/admin/economic-groups',
    },
    {
      title: 'Consessionárias',
      icon: 'business',
      link: '/admin/dealerships',
    },
    {
      title: 'Relatórios',
      icon: 'leaderboard',
      link: '/admin/reports',
    },
    {
      title: 'Configurações',
      icon: 'settings',
      link: '/admin/settings',
    },
    {
      title: 'Suporte',
      icon: 'record_voice_over',
      link: '/admin/support',
    },
    {
      title: 'Sair',
      icon: 'settings_power',
      link: undefined,
    }
  ];

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.drawer.open();
    this.cd.detectChanges();
  }

  goTo(link) {
    this.router.navigate([link]);
  }

}
