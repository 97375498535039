import { Component, OnInit } from '@angular/core';
import { AdminCustomerService } from './admin-customer.service';
import { PaginatedResult } from '../../../app/shared/interfaces/paginated-result';
import DataSource from 'devextreme/data/data_source';
import { buildDataSourceParams } from '../../../app/utils/requests';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-customers',
  templateUrl: './admin-customers.component.html',
  styleUrls: ['./admin-customers.component.scss']
})
export class AdminCustomersComponent implements OnInit {
  gridDataSource: any = {};

  constructor(
    private adminCustomerService: AdminCustomerService,
    public router: Router,
  ) {
    this.gridDataSource = new DataSource({
      key: 'id',
      load: async (loadOptions) => {
        const params = buildDataSourceParams(loadOptions);

        return new Promise((resolve, reject) => {
          this.adminCustomerService.getCustomers(params)
            .subscribe(async (data: PaginatedResult) => {
              resolve(data);
            }, (error) => {
              reject(error);
            });
        });
      },
    });
  }

  ngOnInit(): void {
  }

  edit(event) {
    if (event && event.id) {
      this.router.navigate([`/admin/customers/details/${event.id}`]);
    }
  }

}
