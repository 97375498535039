import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminCustomerService } from '../admin-customer.service';
import { Customer } from '../../../models/customer';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EconomicGroup } from '../../../models/economic-group';
import { AdminEconomicGroupService } from '../../admin-economic-groups/admin-economic-group.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  private sub: any;
  customer: Customer = new Customer();
  customerForm: FormGroup;
  economicGroups: EconomicGroup[] = [];

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private adminCustomerService: AdminCustomerService,
    private adminEconomicGroupService: AdminEconomicGroupService,
  ) {
    this.adminEconomicGroupService.getAll().subscribe((economicGroups) => {
      this.economicGroups = economicGroups;
    });
  }

  reactiveForm() {
    this.customerForm = this.fb.group({
      name: ['', [Validators.required]],
      cpfCnpj: ['', [Validators.required]],
      email: ['', []],
      classification: ['', []],
      cnae: ['', []],
      economicGroupId: ['', [Validators.required]],
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.customerForm.controls[control].hasError(error);
  }

  async submitForm() {
    const formData = this.customerForm.value;

    try {
      if (this.customer.id) {
        this.adminCustomerService
          .updateCustomer(this.customer.id, Object.assign(this.customer, formData))
          .subscribe(() => {
            this.router.navigate(['/admin/customers']);
          });
      } else {
        this.adminCustomerService
          .createCustomer(formData)
          .subscribe((customer) => {
            if (customer.id) {
              this.router.navigate(['/admin/customers']);
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  updateFormValues(customer: Customer) {
    this.customerForm.patchValue(customer);
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params.id) {
        this.adminCustomerService.getCustomer(+params.id).subscribe((customer) => {
          this.customer = customer;

          this.updateFormValues(customer);
        });
      }
    });

    this.reactiveForm();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
