import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent implements OnInit, OnDestroy {
  private sub: any;
  public dealerships: any[] = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.dealerships = JSON.parse(params.dealerships);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  selectDealership(item) {
    localStorage.setItem('dealership', JSON.stringify(item));
    this.router.navigate(['dashboard']);
  }

}
