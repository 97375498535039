import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard.component';
import { DxDataGridModule } from 'devextreme-angular';
import { HeaderModule } from '../shared/header/header.module';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    DxDataGridModule,
    HeaderModule,
    MaterialModule,
  ],
  exports: [DashboardComponent]
})
export class DashboardModule { }
